import { datadogRum } from '@datadog/browser-rum';
import dynamic from 'next/dynamic';
import React from 'react';

const NoSSRApp = dynamic(() => import('./App'), { ssr: false });
datadogRum.init({
    clientToken: 'pub526bec4c84b74b067dc0220baa0e93c7',
    applicationId: 'f65d10fb-be26-452d-8ff8-21d64f943b72',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'digital-forms-acceleration',
    env: 'stage',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    storeContextsAcrossPages: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: () => {
        // event.view.url = event.view.url.toLowerCase();
        return true;
    },
});
datadogRum.startSessionReplayRecording();

// disable SSR for the App due to html2pdf module loading errors
export const browser = () => <NoSSRApp />;
